import { Details, Summary } from '@troon/ui';
import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import type { JSX } from 'solid-js';

type Props = {
	content: Record<string, () => JSX.Element>;
};

export function FrequentlyAskedQuestions(props: Props) {
	return (
		<NoHydration>
			<ul class="divide-y divide-neutral-500">
				<For each={Object.entries(props.content)}>
					{([question, content]) => (
						<li>
							<Details>
								<Summary class="px-4 py-6 group-open:mb-0 group-open:pb-4">{question}</Summary>
								<div class="flex flex-col gap-4 px-4 pb-4">{content()}</div>
							</Details>
						</li>
					)}
				</For>
			</ul>
		</NoHydration>
	);
}
